import axios from "axios";
import AuthService, {authHeader} from "./auth.service";
import {SERVICE_URL, THUMBNAIL_SIZE} from "../js/config";

const API_URL = SERVICE_URL + "/api/v1.2/";
export const NOT_LOGGED_IN_ERROR = "Not logged in";

function isTokenNotValid(error) {
    return error.response && error.response.status === 401 && error.response && error.response.data && error.response.data.code === 'token_not_valid';
}

function withRefreshToken(service, make_wrapped_promise) {
    return new Promise(function(resolve, reject) {
        if(!AuthService.isLoggedIn()) {
            console.log("Not logged in");
            reject(NOT_LOGGED_IN_ERROR);
            return;
        }

        make_wrapped_promise().then(resolve).catch((error) => {
            // The request failed. Check if the error is 401 and due to token being outdated.
            if(isTokenNotValid(error)) {
                AuthService.refresh().then(() => {
                    // Refresh succeeded. Re-try the request
                    console.log("Refresh succeeded")
                    make_wrapped_promise().then(resolve, reject)
                }).catch(((refresh_error) => {
                    if(isTokenNotValid(refresh_error)) {
                        AuthService.logout();
                        AuthService.setLoginExpired(true);
                        service.resetCache();
                        reject(NOT_LOGGED_IN_ERROR);
                    } else {
                        reject(refresh_error);
                    }
                }))
            } else {
                reject(error);
            }
        })
    })
}

export function getEndpoint(obj) {
    if(!obj.entity_type) {
        throw Error('Entity type not specified')
    } else if(obj.entity_type === "figure_revision") {
        return "/revision/"
    } else {
        return `/${obj.entity_type}/`
    }
}

function stripLeadingSlash(path) {
    if(path === null) {
        return null;
    } else if(path.startsWith("/")) {
        return path.slice(1);
    } else {
        return path;
    }
}

function addRank(orderedItems) {
    if(!orderedItems) return [];

    for(let i=0; i < orderedItems.length; i++) {
        orderedItems[i].rank = i;
    }
    return orderedItems;
}

class GoFigrService {
    constructor() {
        this.anonymous = false;
        this.workspaces = null;
        this.userInfo = null;
        this.initialized = false;
        this.currentWorkspace = null;
        this.currentPath = [];
        this.onRefresh = []

        this.resetCache();

        this._refreshPromise = null

        document._gf = this;
    }

    logout() {
        AuthService.logout();
        this.resetCache();
    }

    registerRefreshHandler(handler) {
        this.onRefresh.push(handler);
    }

    async withCache(objectType, key, fetch, useCache=true) {
        let obj;
        if(useCache && objectType in this.pending && this.pending[objectType][key]) {
            // If already pending, wait for the current request to finish
            try {
                obj = await this.pending[objectType][key];
            } catch(error) {
                // If the cached request failed, invalidate the entry and try again
                this.invalidateCache(objectType, key)
                return await this.withCache(objectType, key, fetch)
            }
        } else {
            // Store promise globally in case other components need to fetch it as well
            if(!(objectType in this.pending)) {
                this.pending[objectType] = {}
            }
            const fetch_promise = fetch()
            this.pending[objectType][key] = fetch_promise;
            obj = await fetch_promise;
        }
        return obj;
    }

    invalidateCache(objectType, key) {
        if(objectType in this.pending) {
            this.pending[objectType][key] = null;
        }
    }

    resetCache() {
        if(!('_pending_fetch' in document)) {
            document._pending_fetch = {}
        }
        this.pending = document._pending_fetch;
    }

    get(path) {
        path = stripLeadingSlash(path);
        if(this.anonymous) {
            return axios.get(API_URL + path);
        } else {
            return withRefreshToken(this, () => axios.get(API_URL + path, {headers: authHeader()}));
        }
    }

    post(path, data) {
        path = stripLeadingSlash(path);
        return withRefreshToken(this, () => axios.post(API_URL + path, data, {headers: authHeader()}));
    }

    postAnonymous(path, data) {
        path = stripLeadingSlash(path);
        return axios.post(API_URL + path, data);
    }

    put(path, data) {
        path = stripLeadingSlash(path);
        return withRefreshToken(this, () => axios.put(API_URL + path, data, {headers: authHeader()}));
    }

    patch(path, data) {
        path = stripLeadingSlash(path);
        return withRefreshToken(this, () => axios.patch(API_URL + path, data, {headers: authHeader()}));
    }

    delete(path) {
        path = stripLeadingSlash(path);
        return withRefreshToken(this, () => axios.delete(API_URL + path, {headers: authHeader()}));
    }

    async initialize() {
        if(this.initialized) {
            return this;
        } else {
            if(!this._refreshPromise) {
                if(!AuthService.isLoggedIn()) {
                    this._refreshPromise = this.refreshAnonymous();
                } else {
                    this._refreshPromise = this.refresh()
                }
            }

            await this._refreshPromise;
            return this;
        }
    }

    async registerUser(userObj) {
        const response = await this.postAnonymous("/user/", userObj);
        return response.data;
    }

    async getUserInfo(username) {
        return this.withCache("user", username, async () => {
            let response;
            if (!username) {
                response = await this.get("/user");
                return response.data[0];
            } else {
                response = await this.get("/user/" + username + "/");
                return response.data;
            }
        })
    }

    async updateUser(userInfo) {
        const response = await this.patch("/user/"  + userInfo.username + "/", userInfo);
        this.invalidateCache("user", userInfo.username);
        return response.data;
    }

    async searchUsers(query) {
        return this.withCache("user_search", query, async () => {
            const response = await this.get("/user?q=" + encodeURIComponent(query));
            return response.data;
        })
    }

    requestEmailVerification(userInfo) {
        return this.post("/user/" + userInfo.username + "/verify_email/", {})
    }

    verifyEmail(userInfo, token) {
        return this.post("/user/" + userInfo.username + "/verify_email/", {token: token})
    }

    async getWorkspace(apiId) {
        return this.withCache("workspace", apiId, async () => {
            const response = await this.get("/workspace/" + apiId);
            return response.data;
        })
    }

    async getLinkSharing(endpoint, object) {
        return this.withCache("link_sharing", object.api_id, async () => {
            const response = await this.get(endpoint + object.api_id + "/share/link");
            return response.data;
        })
    }

    async setLinkSharing(endpoint, object, sharing_obj) {
        const response = await this.post(endpoint + object.api_id + "/share/link/", sharing_obj);
        this.invalidateCache("link_sharing", object.api_id);
        return response.data;
    }

    async getUserSharing(endpoint, object) {
        return this.withCache("user_sharing", object.api_id, async () => {
            const response = await this.get(endpoint + object.api_id + "/share/user");
            return response.data;
        })
    }

    async setUserSharing(endpoint, object, sharing_obj) {
        const response = await this.post(endpoint + object.api_id + "/share/user/", sharing_obj);
        this.invalidateCache("user_sharing", object.api_id);
        return response.data;
    }

    async createWorkspace(workspace) {
        const response = await this.post("/workspace/", workspace);
        return response.data;
    }

    async deleteWorkspace(workspace) {
        const response = await this.delete("/workspace/" + workspace.api_id);
        return response.data;
    }

    async updateWorkspace(workspace) {
        const response = await this.patch("/workspace/" + workspace.api_id + "/", workspace);
        this.invalidateCache("workspace", workspace.api_id);
        return response.data;
    }

    async getWorkspaceMembers(workspace) {
        const response = await this.get("/workspace/" + workspace.api_id + "/members/");
        return response.data;
    }

    async changeMembership(workspace, username, membership_type) {
        const memberData = {username: username, membership_type: membership_type}
        const response = await this.post("/workspace/" + workspace.api_id + "/members/change/", memberData)
        return response.data;
    }

    async addMember(workspace, username, membership_type) {
        const memberData = {username: username, membership_type: membership_type}
        const response = await this.post("/workspace/" + workspace.api_id + "/members/add/", memberData)
        return response.data;
    }

    async removeMember(workspace, username) {
        const memberData = {username: username, membership_type: null}
        const response = await this.post("/workspace/" + workspace.api_id + "/members/remove/", memberData)
        return response.data;
    }

    async inviteMember(workspace, email, membership_type) {
        const inviteData = {email: email,
                                 workspace: workspace.api_id,
                                 membership_type: membership_type}
        const response = await this.post("/invitations/workspace/", inviteData)
        return response.data;
    }

    async cancelInvitation(invitation) {
        const response = await this.delete("/invitations/workspace/" + invitation.api_id);
        return response.data;
    }

    async acceptInvitation(token) {
        const response = await this.post("/invitations/workspace/" + token + "/accept");
        return response.data;
    }

    async resendInvitation(workspace, invitation) {
        console.log(invitation);
        await this.cancelInvitation(invitation)
        const response = await this.inviteMember(workspace, invitation.email, invitation.membership_type)
        return response.data;
    }

    async getInvitations(workspace) {
        const response = await this.get("/workspace/" + workspace.api_id + "/invitations/");
        return response.data;
    }

    async retrieveInvitation(token) {
        const response = await this.get("/invitations/workspace/" + token);
        return response.data;
    }

    async getWorkspaceLog(apiId, useCache=true) {
        const gf = this;
        return this.withCache("workspace_log", apiId, async () => {
            const response = await this.get("/workspace/" + apiId + "/log/");
            return response.data.map(function(obj) {
                // Logs are shallow. Store the parent so that we can fetch details later.
                obj.fetch = function() {
                    return gf.getWorkspaceLogItem(apiId, obj.api_id);
                }
                return(obj)
            })
        }, useCache)
    }

    async getWorkspaceLogItem(apiId, logItemId) {
        return this.withCache("workspace_log", apiId + "/" + logItemId, async () => {
            const response = await this.get("/workspace/" + apiId + "/log/" + logItemId + "/");
            return response.data;
        })
    }

    async getAnalysis(apiId) {
        return this.withCache("analysis", apiId, async () => {
            const response = await this.get("/analysis/" + apiId);
            return response.data;
        });
    }

    async createAnalysis(analysis) {
        const response = await this.post("/analysis/", analysis);
        return response.data;
    }

    async deleteAnalysis(analysis) {
        const response = await this.delete("/analysis/" + analysis.api_id);
        return response.data;
    }

    async getAnalysisLog(apiId) {
        const gf = this;
        return this.withCache("analysis_log", apiId, async () => {
            const response = await this.get("/analysis/" + apiId + "/log/");
            return response.data.map(function(obj) {
                // Logs are shallow. Store the parent so that we can fetch details later.
                obj.fetch = function() {
                    return gf.getAnalysisLogItem(apiId, obj.api_id);
                }
                return(obj)
            })
        });
    }

    async getAnalysisLogItem(apiId, logItemId) {
        return this.withCache("analysis_log", apiId + "/" + logItemId, async () => {
            const response = await this.get("/analysis/" + apiId + "/log/" + logItemId + "/");
            return response.data;
        });
    }

    async getFigure(apiId) {
        return this.withCache("figure", apiId, async () => {
            const response = await this.get("/figure/" + apiId);
            return response.data;
        });
    }

    async createFigure(figure) {
        const response = await this.post("/figure/", figure);
        return response.data;
    }

    async deleteFigure(figure) {
        const response = await this.delete("/figure/" + figure.api_id);
        return response.data;
    }

    async updateFigure(figure) {
        const response = await this.patch("/figure/"  + figure.api_id + "/", figure);
        this.invalidateCache("figure", figure.api_id);
        return response.data;
    }

    async updateAnalysis(analysis) {
        const response = await this.patch("/analysis/"  + analysis.api_id + "/", analysis);
        this.invalidateCache("analysis", analysis.api_id);
        return response.data;
    }

    async getRevision(apiId) {
        return this.withCache("revision", apiId, async () => {
            const response = await this.get("/revision/" + apiId);
            return response.data;
        });
    }

    async deleteRevision(revision) {
        const response = await this.delete("/revision/" + revision.api_id);
        return response.data;
    }

    async getRecents(workspace) {
        return this.withCache("workspace_recents", workspace.api_id, async () => {
            const response = await this.get("/workspace/" + workspace.api_id + "/recent");
            return response.data;
        });
    }

    async getObjectSize(obj) {
        const endpoint = getEndpoint(obj);
        return this.withCache("object_size", obj.api_id, async () => {
            const response = await this.get(endpoint + obj.api_id + "/size/");
            return response.data;
        })
    }

    async getRevisionData(apiId) {
        return this.withCache("data", apiId, async () => {
            const response = await this.get("/data/" + apiId);
            return response.data;
        });
    }

    async getThumbnail(obj, size) {
        if(!size) {
            size = THUMBNAIL_SIZE;
        }

        const endpoint = getEndpoint(obj);
        return this.withCache("thumbnails", obj.api_id, async () => {
            const response = await this.get(endpoint + obj.api_id + "/thumbnail/" + size);
            return response.data;
        })
    }

    /*
    Subscriptions
     */
    async getSubscription(workspace) {
        return this.withCache("subscription", workspace.api_id, async () => {
            const response = await this.get("/workspace/" + workspace.api_id + "/subscription");
            return response.data;
        });
    }

    async updateSubscription(workspace, subscription) {
        const response = await this.patch("/workspace/"  + workspace.api_id + "/subscription/", subscription);
        this.invalidateCache("subscription", workspace.api_id);
        return response.data;
    }

    async updatePlan(workspace, newPlanId) {
        return this.updateSubscription(workspace, {plan: newPlanId})
    }

    async listPlans() {
        return this.withCache("plans", "", async () => {
            const response = await this.get("/plan/");
            return response.data;
        })
    }

    /*
    API keys
     */
    async listApiKeys() {
        const response = await this.get("/api_key/");
        return response.data;
    }

    async createApiKey(name, expiry, workspace) {
        const response = await this.post("/api_key/",
            {name: name,
                  expiry: expiry,
                  workspace: workspace ? workspace.api_id : null});
        return response.data;
    }

    async revokeApiKey(api_id) {
        const response = await this.delete("/api_key/" + api_id);
        return response.data;
    }

    /*
    Admin
     */
    async listUsers() {
        const response = await this.get("/admin/user_management/");
        return response.data;
    }

    async globalActivityLog(startDate=null) {
        let response;
        if(startDate === undefined || startDate === null) {
            response = await this.get("/admin/activity/");
        } else {
            response = await this.get("/admin/activity/?created_on__gte=" + startDate.toISOString())
        }
        return response.data.filter(x => x !== null);
    }

    async indexStatus(startDate, status) {
        let query = "?";
        query += "created_on__gte=" +  startDate.toISOString();
        if(status && status.length > 0) {
            query += "&status__in=" + status.join(",")
        }

        const response = await this.get("/admin/index_status/" + query);
        return response.data;
    }

    async getIndexData(api_id) {
        const response = await this.get("/admin/index_data/" + api_id);
        return response.data;
    }

    /* Subscriptions */
    async listAllSubscriptions() {
        const response = await this.get("/admin/subscription/");
        return response.data;
    }

    async updateSubscriptionProperties(data) {
        const response = await this.patch("/admin/subscription/" + data.api_id + "/", data);
        return response.data;
    }

    async createSubscription(data) {
        const response = await this.post("/admin/subscription/", data);
        return response.data;
    }

    async deleteSubscription(sub) {
        const response = await this.delete("/admin/subscription/" + sub.api_id + "/");
        return response.data;
    }

    /* Plans */
    async listAllPlans() {
        const response = await this.get("/admin/plan/");
        return response.data;
    }

    async updatePlanProperties(data) {
        const response = await this.patch("/admin/plan/" + data.api_id + "/", data);
        return response.data;
    }

    async createPlan(data) {
        const response = await this.post("/admin/plan/", data);
        return response.data;
    }

    async deletePlan(plan) {
        const response = await this.delete("/admin/plan/" + plan.api_id + "/");
        return response.data;
    }

    /*
    Search
     */
    async textSearch(text) {
        return this.withCache("search", text, async () => {
            const response = await this.post("/search/",
                {search_type: "text",
                      k: 100,
                      query: text});
            return addRank(response.data);
        })
    }

    async imageSearch(image_data) {
        const response = await this.post("/search/",
            {search_type: "image",
                k: 100,
                image: btoa(image_data)});
        return addRank(response.data);
    }

    async refresh() {
        this.resetCache();

        this.userInfo = await this.getUserInfo(null);
        this.workspaces = await this.listWorkspaces();

        for(const callback of this.onRefresh) {
            callback(this);
        }

        this.initialized = true;

        return this;
    }

    async refreshAnonymous() {
        this.resetCache();

        this.anonymous = true;
        this.userInfo = {username: null, first_name: null, last_name: null, email: null}
        this.workspaces = []

        this.recentFigures = []
        this.recentAnalyses = []

        for(const callback of this.onRefresh) {
            callback(this);
        }

        this.initialized = true;

        return this;
    }

    async listWorkspaces() {
        const result = await this.get("workspace")
        return result.data
    }
}

export function orderByMostRecent(items) {
    if(!items || !items.length) {
        return [];
    }

    const exemplar = items[0];
    if('timestamp' in exemplar) {
        return items.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    } else if('last_activity_on' in exemplar) {
        return items.sort((a, b) => new Date(b.last_activity_on) - new Date(a.last_activity_on))
    } else if('created' in exemplar) {
        return items.sort((a, b) => new Date(b.created) - new Date(a.created))
    } else {
        throw Error("Array to be sorted doesn't contain known timestamps")
    }
}

export default new GoFigrService();
